.dropdownContainer {
    border-radius: 0.5rem;

    padding: 0.5rem;

    box-shadow: 0 0.35rem 0.6rem rgba(0, 0, 0, 0.67) !important;

    z-index: 10;

    .btn {
            background-color: var(--form-button-color) !important;
            border-color: var(--form-button-color) !important;
            color: var(--form-button-text-color) !important;
            height: auto;

            &:hover {
                    opacity: 0.8 !important;
            }
    }



    .formio-component:last-child {
        margin: 0;
    }
}
